import React from 'react';

export const getAboutUsTitleHr = () => {
    return (<h1>O nama</h1>);
};

export const getAboutUsTitleDe = () => {
    return (<h1>Über uns</h1>);
};

export const getAboutUsContentHr = () => {
    return (<div>
                <p>
                    Pizzeria Venera otvorila je svoja vrata daleke 1998. godine u sklopu malonogometnog igrališta Venera u Pleškovcu, s ciljem pružanja bogate gastronomske ponude na zadovoljstvo naših posjetitelja.
                </p>
                <p>
                    Dočekujemo Vas u prekrasnom ambijentu uz ljubazno osoblje, raznovrsnom ponudom pizza, tjestenina, međimurskih specijateteta (meso iz tiblice, suhi jezici), te neizostavnim desertima uz bogatu ponudu pića i napitaka iz koje izdvajamo vrhunska međimurska stolna vina i butelje.
                </p>
                <p>
                    Za veće grupe nudimo iznamljivanja sportskog kompleksa s malonogometnim igralištem, slačionicama, kuhinjom i šankom. Također nudimo iznajmljivanje teniskog terena sa mogućnošću noćne rasvjete.
                </p>
                <p>
                    Također nudimo gledanje sportskih sadržaja na MAX TV i VIP TV, a za najmlađe imamo dječji kutak sa igračkama te veliko dječje igralište.
                </p>
                <p>
                    Nudimo mogućnost plaćanja karticama (Amex, Visa, Maestro, MasterCard).
                </p>
                <p>
                    Svake srijede imamo akciju “PIZZA PARTY”, gdje se uz veliku pizzu Cappricozu dobiva malo Karlovačko pivo ili Cedevita za samo <b>9,00 €</b>.
                </p>

                <div>
                    <span>Broj tel. i mogućnost naruđbe pizza - </span>
                    <a href="tel:040-855-999">040-855-999</a>
                </div>
            </div>
    );
};

export const getAboutUsContentDe = () => {
    return (<div>
                <p>
                    Pizzeria Venera hat ihre Tür seit 1998 geöffnet innerhalb vom kleinen Fußballplatz Venera in Pleškovc, mit dem Ziel zur Bereitstellung vom reichem Gastronomischem Angebot zur Zufriedenheit der Besucher.
                </p>
                <p>
                    Wir erwarten Sie im wnderschönem Ambiente zusammen mit freundlichem Personal, vielfältigem Angebot von Pizzen, Pasta, Spezialitätn aus Međimurje (Fleisch, Rinderzunge), und unverzichtbare Deserts mit reichem Angebot von Getränken aus welchem wir Tafelwein aus Međimurje und Bouteille empfehlen.
                </p>
                <p>
                    Für größere Gruppen bieten wir Vermietung vom Sportkomplex mit kleinen Fußballplatz, Umkleidekabinen, Küche und einer Bar an. Wir bieten auch die Vermietung vom Tenisspielplatzt mit Nachtbeleuchtung an.
                </p>
                <p>
                    Wir bieten auch anschauen vom Sportinhalten am MAX TV und VIP TV an, und für die jüngsten haben wir eine Kinderecke mit Spielzeug und drausen haben wir einen großen Kinderspielplatz.
                </p>
                <p>
                    Wir bieten auch zahlungsmöglichkeiten mit Kreditkarten und Debitkarten (Amex, Visa, Maestro, MasterCard) an.
                </p>
                <p>
                    Jeden Mitwoch haben wir eine Aktion “PIZZA PARTY”, wo Sie zur einer grosen Pizza Cappricoza auch ein kleines Karlovačko Bier oder Cedevita kriegen zum preis vom nur <b>9,00 €</b>.
                </p>

                <div>
                    <span>Telefonnummer und die Kontaktnummer zur bestellung - </span>
                    <a href="tel:040-855-999">040-855-999</a>
                </div>
            </div>
    );
};