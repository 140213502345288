import React from 'react';

export const getPizzeriaContentOfferHr = () => {
    return (
        <div className="pizzeria-offer-container">
            <h3 className="pizzeria-offer-heading">
                Svake srijede imamo akciju “PIZZA PARTY”, gdje se uz <strong>veliku pizzu Cappricozu</strong> dobiva <strong>malo Karlovačko pivo</strong> ili <strong>Cedevita</strong> za samo <strong>9,00 €</strong>.
            </h3>
        </div>
    )
};

export const getPizzeriaContentOfferDe = () => {
    return (
        <div className="pizzeria-offer-container">
            <h3 className="pizzeria-offer-heading">
                Jeden Mitwoch haben wir eine Aktion “PIZZA PARTY”, wo Sie zur einer <strong>grosen Pizza Cappricoza</strong> auch ein <strong>kleines Karlovačko Bier</strong> oder <strong>Cedevita</strong> kriegen zum preis vom nur <strong>9,00 €</strong>.
            </h3>
        </div>
    )
};